import {FirebaseOptions} from '@angular/fire/app';

export const environment = {
  environment: 'Gallix',
  production: true,
  storageUrl: 'gs:gallix-app.firebasestorage.app',
  firebaseConfig: {
    apiKey: "AIzaSyD4bJXiBSpWFp21jptoh71mg-v9al5GuzY",
    authDomain: "gallix-app.firebaseapp.com",
    projectId: "gallix-app",
    storageBucket: "gallix-app.firebasestorage.app",
    messagingSenderId: "70607681762",
    appId: "1:70607681762:web:8c14b8c5e0f5f310b346bb",
    measurementId: "G-8PYJK0EC36"
  } as FirebaseOptions
};
