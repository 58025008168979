import {createAction, props} from '@ngrx/store';
import {IStore} from '../../../shared/shared-models/store/store';
import {DisabledRules, LineColour, TableNavSettings} from '../../../shared-utilities/models-old/datastructures';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IEmailSettings} from '../models/settings-models';
import {ISharedModalBasic} from '../../../shared/shared-models/modals/shared-modal-basic';

//===============================================================================================================
// Get NGP Reports Preview Columns
//===============================================================================================================
export const getNGPReportColPreviewSettings = createAction(
  '[User Settings][NGP Report] Get NGP Report Column Preview Settings',
);

export const getNGPReportColPreviewSuccess = createAction(
  '[User Settings][NGP Report] Get NGP Report Column Preview Success',
  props<{ store: IStore, colPreview: { [key: number]: string } }>(),
);

export const getNGPReportColPreviewFailure = createAction(
  '[User Settings] Get NGP Report Column Preview Failure',
  props<{ errors: IError[]; store: IStore }>(),
);

//===============================================================================================================
// Set NGP Preview Columns
//===============================================================================================================
export const setNGPReportColPreviewSettings = createAction(
  '[User Settings][NGP Report] Set NGP Report Column Preview Settings',
  props<{ columnData: {} }>(),
);

export const setNGPReportColPreviewSettingsSuccess = createAction(
  '[User Settings][NGP Report] Set NGP Report Column Preview Settings Success',
  props<{ columnData: {}; store: IStore }>(),
);

export const setNGPReportColPreviewSettingsFailure = createAction(
  '[User Settings][NGP Report] Set NGP Report Column Preview Settings Failure',
  props<{ error: IError, store: IStore }>(),
);

//===============================================================================================================
// Get Table Options
//===============================================================================================================
export const getTableSettings = createAction(
  '[User Settings][Table Settings] Get Table Settings',
);

export const getTableSettingsSuccess = createAction(
  '[User Settings][Table Settings] Get Table Settings Success',
  props<{ tableNavSettings: TableNavSettings, fitToGrid: boolean, store: IStore }>(),
);

export const getTableSettingsFailure = createAction(
  '[User Settings][Table Nav] Get Table Settings Failure',
  props<{ error: IError, store: IStore }>(),
);

//===============================================================================================================
// Set Fit To Grid
//===============================================================================================================

export const setFitToGrid = createAction(
  '[Shared Grid][Grid Layout] Set Fit To Grid',
  props<{ fitToGrid: boolean | null }>(),
);

export const toggleFitToGrid = createAction(
  '[Shared Grid][Grid Layout] toggle Fit To Grid',
);

export const setFitToGridSuccess = createAction(
  '[FitToGrid] Set Fit To Grid Success',
  props<{ fitToGrid: boolean }>(),
);

export const setFitToGridFailure = createAction(
  '[FitToGrid] Set Fit To Grid Failure',
  props<{ error: IError }>(),
);

//===============================================================================================================
// Set Table Navigation Options
//===============================================================================================================
export const setTableNavSettings = createAction(
  '[User Settings][Table Nav] Set Table Nav Settings',
  props<{ settings: TableNavSettings }>(),
);

export const setTableNavSettingsSuccess = createAction(
  '[User Settings][Table Nav] Set Table Nav Settings Success',
  props<{ settings: TableNavSettings, store: IStore }>(),
);

export const setTableNavSettingsFailure = createAction(
  '[User Settings][Table Nav] Set Table Nav Settings Failure',
  props<{ error: IError, store: IStore }>(),
);

//===============================================================================================================
// Get Item Disabling Rules Options
//===============================================================================================================
export const getItemDisablingRules = createAction(
  '[Store Settings][Item Disabling Rules] Get Item Disabling Rules',
);

export const getItemDisablingRulesSuccess = createAction(
  '[Store Settings][Item Disabling Rules] Get Item Disabling Rules Success',
  props<{ rulesDoc: DisabledRules, store: IStore }>(),
);

export const getItemDisablingRulesFailure = createAction(
  '[Store Settings][Item Disabling Rules] Get Item Disabling Rules Failure',
  props<{ error: IError, store: IStore }>(),
);
//===============================================================================================================
// Get Line Colour
//===============================================================================================================
export const getLineColour = createAction(
  '[Store Settings][Line Colour] Get Line Colour',
);

export const getLineColourSuccess = createAction(
  '[Store Settings][Line Colour] Get Line Colour Success',
  props<{ colour: LineColour, store: IStore }>(),
);

export const getLineColourFailure = createAction(
  '[Store Settings][Line Colour] Get Line Colour Failure',
  props<{ error: IError, store: IStore }>(),
);

//===============================================================================================================
// Get Stock Manager Preview Columns
//===============================================================================================================
export const getStockManagerColPreviewSettings = createAction(
  '[User Settings][Stock Manager] Get Stock Manager Column Preview Settings',
);

export const getStockManagerColPreviewSettingsSuccess = createAction(
  '[User Settings][Stock Manager] Get Stock Manager Column Preview Settings Success',
  props<{ colPreview: { [key: number]: string }, store: IStore }>(),
);

export const getStockManagerColPreviewSettingsFailure = createAction(
  '[User Settings][Stock Manager] Get Stock Manager Column Preview Settings Failure',
  props<{ errors: IError }>(),
);

//===============================================================================================================
// Set NGP Preview Columns
//===============================================================================================================
export const setStockManagerPreviewColumns = createAction(
  '[User Settings][Stock Manager] Set Stock Manager Preview Columns',
  props<{ columnData: {} }>(),
);

export const setStockManagerPreviewColumnsSuccess = createAction(
  '[User Settings][Stock Manager] Set Stock Manager Preview Columns Success',
  props<{ columnData: {}; store: IStore }>(),
);

export const setStockManagerPreviewColumnsFailure = createAction(
  '[User Settings][Stock Manager] Set Stock Manager Preview Columns Failure',
  props<{ error: IError, store: IStore }>(),
);

//===============================================================================================================
// Get All User Email Settings
//===============================================================================================================
export const getAllUserEmailSettings = createAction(
  '[User Settings][Email Details] Get All Users Email Settings',
);

export const getAllUserEmailSettingsSuccess = createAction(
  '[User Settings][Email Details] Get All Users Email Settings Success',
  props<{ usersEmailSettings: { [userId: string]: IEmailSettings }; store: IStore }>(),
);

export const getAllUserEmailSettingsFailure = createAction(
  '[User Settings][Email Details] Get All Users Email Settings Failure',
  props<{ error: IError }>(),
);


//===============================================================================================================
// Get Supplier Emails
//===============================================================================================================
export const getSupplierEmails = createAction(
  '[Auto Orders][Supplier Emails] Get Supplier Emails',
  props<{ supplierIds: string[] }>(),
);

export const getSupplierEmailsSuccess = createAction(
  '[[Auto Orders][Supplier Emails] Get Supplier Emails Success',
  props<{ supplierEmails: { [suppId: string]: string[] }; store: IStore }>(),
);

export const getSupplierEmailsFailure = createAction(
  '[Auto Orders][Supplier Emails] Get Supplier Emails Failure',
  props<{ error: IError, store: IStore }>(),
);

//===============================================================================================================
// Test User Email Details
//===============================================================================================================

export const testEmail = createAction(
  '[Store Settings][Test Email] Test Email',
  props<{ storeId: string; userId: string; senderUserId: string; }>(),
);

export const testEmailSuccess = createAction(
  '[Store Settings][Test Email] Test Email Success',
  props<{ status: string }>(),
);

export const testEmailFailure = createAction(
  '[Store Settings][Test Email] Test Email Failure',
  props<{ error: IError }>(),
);

export const showModal = createAction(
  '[Store Settings][Test Email] Show Modal',
  props<{ modalProps: ISharedModalBasic }>(),
);

