import {createReducer, on} from '@ngrx/store';
import * as StoreSettingsActions from './settings.actions';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {LineColour, TableNavSettings} from '../../../shared-utilities/models-old/datastructures';
import {IEmailSettings, IStoreSettings, IUserSettings} from '../models/settings-models';

export interface SettingsState extends IInitialState {
  storeSettings: { [storeId: string]: IStoreSettings };
  userSettings: IUserSettings;
  usersEmailSettings: { [storeId: string]: { [userId: string]: IEmailSettings } };
  isUserEmailSettingsLoading: boolean;
}

export const initialOptionsState: SettingsState = {
  errors: [],
  storeSettings: {},
  userSettings: {} as IUserSettings,
  usersEmailSettings: {},
  isUserEmailSettingsLoading: false,
};

export const SettingsReducer = createReducer(
  initialOptionsState,

  // ===========================================================================
  // get NGP Report Col Preview (Success)
  // ===========================================================================
  on(StoreSettingsActions.getNGPReportColPreviewSuccess, (state, {store, colPreview}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      ngpReportColPreviewSettings: colPreview,
    },
  })),

  // ===========================================================================
  // get NGP Report Col Preview (Failure)
  // ===========================================================================
  on(StoreSettingsActions.getNGPReportColPreviewFailure, (state, {errors, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      [store.storeId]: {
        ...state.userSettings[store.storeId],
        ngpReportColPreviewSettings: null,
      },
    },
    errors: [...state.errors, ...errors],
  })),

  // ===========================================================================
  // set NGP Report Preview Columns
  // ===========================================================================
  on(StoreSettingsActions.setNGPReportColPreviewSettingsSuccess, (state, {columnData, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      ngpReportColPreviewSettings: columnData,
    },
  })),

  on(StoreSettingsActions.setNGPReportColPreviewSettingsFailure, (state, {error, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      ngpReportColPreviewSettings: {},
    },
    errors: [...state.errors, error],
  })),

  // ===========================================================================
  // get Table Settings (Success & Failure)
  // ===========================================================================
  on(StoreSettingsActions.setTableNavSettingsSuccess, (state, {settings, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      tableNavigationSettings: settings,
    },
  })),
  on(StoreSettingsActions.getTableSettingsSuccess, (state, {tableNavSettings, fitToGrid, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      tableNavigationSettings: tableNavSettings,
      fitToGrid: fitToGrid,
    },
  })),

  on(StoreSettingsActions.setTableNavSettingsFailure, (state, {error, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      tableNavigationSettings: {} as TableNavSettings,
    },
    errors: [...state.errors, error],
  })),

  on(StoreSettingsActions.setFitToGridSuccess, (state, {fitToGrid}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      fitToGrid: fitToGrid ? fitToGrid : !state.userSettings.fitToGrid,
    },
  })),


  on(StoreSettingsActions.toggleFitToGrid, (state) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      fitToGrid: !state.userSettings.fitToGrid,
    },
  })),

  // ===========================================================================
  // get Disabled Rules (Success & Failure)
  // ===========================================================================
  on(StoreSettingsActions.getItemDisablingRulesSuccess, (state, {rulesDoc, store}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [store.storeId]: {
        ...state.storeSettings[store.storeId],
        disabledRules: rulesDoc,
      },
    },
  })),

  on(StoreSettingsActions.getItemDisablingRulesFailure, (state, {error, store}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [store.storeId]: {
        ...state.storeSettings[store.storeId],
        disabledRules: {},
      },
    },
    errors: [...state.errors, error],
  })),

  // ===========================================================================
  // get Line Colour (Success & Failure)
  // ===========================================================================
  on(StoreSettingsActions.getLineColourSuccess, (state, {colour, store}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [store.storeId]: {
        ...state.storeSettings[store.storeId],
        lineColour: colour,
      },
    },
  })),

  on(StoreSettingsActions.getLineColourFailure, (state, {error, store}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [store.storeId]: {
        ...state.storeSettings[store.storeId],
        lineColour: {} as LineColour,
      },
    },
    errors: [...state.errors, error],
  })),
  // ===========================================================================
  // get Stock Manager Column Preview Settings (Success & Failure)
  // ===========================================================================
  on(StoreSettingsActions.getStockManagerColPreviewSettingsSuccess, (state, {colPreview, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      stockManagerColPreviewSettings: colPreview,
    },
  })),

  on(StoreSettingsActions.setNGPReportColPreviewSettingsFailure, (state, {error}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      ngpReportColPreviewSettings: {},
    },
    errors: [...state.errors, error],
  })),

  // ===========================================================================
  // set Stock Manager Preview Columns
  // ===========================================================================
  on(StoreSettingsActions.setStockManagerPreviewColumnsSuccess, (state, {columnData, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      stockManagerColPreviewSettings: columnData,
    },
  })),

  on(StoreSettingsActions.setStockManagerPreviewColumnsFailure, (state, {error, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      stockManagerColPreviewSettings: {},
    },
    errors: [...state.errors, error],
  })),

  // ===========================================================================
  // get All User Email Settings
  // ===========================================================================
  on(StoreSettingsActions.getAllUserEmailSettings, (state) => ({
    ...state,
    isUserEmailSettingsLoading: true,
  })),
  on(StoreSettingsActions.getAllUserEmailSettingsSuccess, (state, {usersEmailSettings, store}) => ({
    ...state,
    usersEmailSettings: {
      ...state.usersEmailSettings,
      [store.storeId]: usersEmailSettings,
    },
    isUserEmailSettingsLoading: false,
  })),
  on(StoreSettingsActions.getAllUserEmailSettingsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
    isUserEmailSettingsLoading: false,
  })),
  on(StoreSettingsActions.testEmail, (state) => ({
    ...state,
    isUserEmailSettingsLoading: true,
  })),
  on(StoreSettingsActions.testEmailSuccess, (state) => ({
    ...state,
    isUserEmailSettingsLoading: false,
  })),
  on(StoreSettingsActions.testEmailFailure, (state) => ({
    ...state,
    isUserEmailSettingsLoading: false,
  })),
);

