import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {setItemDisabledStatus} from '../../store/shared-grid.actions';

@Component({
  selector: 'app-cell-renderer-disable-item',
  templateUrl: './cell-renderer-disable-item.component.html',
  styleUrls: ['./cell-renderer-disable-item.component.scss'],
})
export class CellRendererDisableItemComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;
  width: string;
  value: boolean;
  type: string;
  departments: {} = {};

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.value = params.value;
    this.type = params.colDef.field;
  }

  onLockIconClick(): void {
    if (this.params.data._tags.includes('disabled')) {
      this.params.data._tags = this.params.data._tags.filter(tag => tag !== 'disabled');
    } else {
      this.params.data._tags = [...this.params.data._tags, 'disabled'];
    }
    this.params.data.disabled = !this.params.data.disabled;
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    this.params.data.isEdited = this.params.data[this.params.colDef.field] !== this.params.data.originalValue[this.params.colDef.field].value;
    this.store.dispatch(setItemDisabledStatus({
      item: this.params.data,
      enable: !this.params.data._tags.includes('disabled'),
    }));
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}



