import {CellClassParams, ColDef, ValueFormatterParams} from 'ag-grid-community';
import * as CellRenderers from '../../../../../shared-modules/shared-grid/components-cell-renderers';
import * as CellEditors from '../../../../../shared-modules/shared-grid/components-cell-editors';
import {DataAdditionalIcons} from '../../data-additional-icons';
import {DataAdditionalNumbers} from '../../data-additional-numbers';
import {DateUtils} from '../../../../utils-old/shared-utils-old/date-utils';

const costMissTooltip = 'Latest cost is not the same as last GRV price.';
const itemRecentlyCreatedTooltip = 'Item was recently created.';
const itemEditedTooltip = 'Item was marked as edited.';
const itemErrorTooltip = `Item has an error and can't be submitted`;

export const ngpReportGridColDefs: ColDef[] = [
  // Icons ------------------------------
  {
    field: 'icons',
    headerName: '',
    hide: false,
    pinned: 'left',
    resizable: true,
    lockPinned: true,
    lockPosition: true,
    cellRenderer: CellRenderers.CELL_RENDERER_ICONS,
    cellRendererParams: {
      dataAdditional: {
        isCostMissIconVisible: true,
        costMissIconTooltip: costMissTooltip,
        isItemRecentlyCreatedIconVisible: true,
        itemRecentlyCreatedIconTooltip: itemRecentlyCreatedTooltip,
        isItemEditedIconVisible: true,
        itemEditedIconTooltip: itemEditedTooltip,
        isErrorIconVisible: true,
        itemErrorIconTooltip: itemErrorTooltip,
      } as DataAdditionalIcons,
    },
    width: 100,
  },
  // General ----------------------------
  {
    field: 'stockId',
    headerName: 'Code',
    hide: false,
    pinned: 'left',
    resizable: true,
    lockPinned: true,
    lockPosition: true,
    type: ['alignRight'],
    editable: false,
    width: 149,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_DISABLE_ITEM,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },

  {
    field: 'desc',
    headerName: 'Description',
    hide: false,
    lockPinned: true,
    width: 400,
    resizable: true,
    editable: false,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },
  // Cost -------------------------------
  {
    field: 'latestCost',
    headerName: 'Cost Price',
    hide: true,
    type: ['alignRight'],
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellEditor: false,
    cellRendererParams: {
      dataAdditional: {
        decimalPlaces: 2,
      } as DataAdditionalNumbers,
    },
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },
  {
    field: 'prevCostPrice',
    headerName: 'Prev Cost Price',
    hide: true,
    type: ['alignRight'],
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellEditor: false,
    cellRendererParams: {
      dataAdditional: {
        decimalPlaces: 2,
      } as DataAdditionalNumbers,
    },
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  // Supplier ---------------------------
  {
    field: 'regularSuppCode',
    headerName: 'Supplier',
    hide: true,
    resizable: true,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'suppCode',
    headerName: 'Supplier Stock Code',
    hide: true,
    width: 250,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'suppUsedLastDate1',
    headerName: 'GRV Date',
    hide: true,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
  },
  {
    field: 'suppUsedLastDate2',
    headerName: 'Prev GRV Date',
    hide: true,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
  },
  // Department -------------------------
  {
    field: 'dep',
    headerName: 'Department',
    hide: true,
    width: 300,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_DEPARTMENTS,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'subDep',
    headerName: 'Sub Department',
    hide: true,
    width: 300,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_DEPARTMENTS,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },
  // Price ------------------------------
  {
    field: 'sellPriIncl1',
    headerName: 'Price (incl)',
    hide: false,
    lockPinned: true,
    lockPosition: false,
    pinned: 'right',
    resizable: true,
    type: ['alignRight'],
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellEditor: CellEditors.CELL_EDITOR_PRICE_NGP,
    cellRendererParams: {
      dataAdditional: {
        decimalPlaces: 2,
      } as DataAdditionalNumbers,
    },
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },
  {
    field: 'nominalGP',
    headerName: 'NGP (Nominal GP)',
    hide: false,
    pinned: 'right',
    lockPosition: false,
    type: ['alignRight'],
    width: 105,
    resizable: true,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellEditor: CellEditors.CELL_EDITOR_PRICE_NGP,
    cellRendererParams: {
      dataAdditional: {
        decimalPlaces: 2,
      } as DataAdditionalNumbers,
    },
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },
  {
    field: 'diffGP',
    headerName: 'GP % Diff',
    hide: false,
    pinned: 'right',
    lockPinned: true,
    lockPosition: false,
    width: 140,
    resizable: true,
    type: ['alignRight'],
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellEditor: false,
    cellRendererParams: {
      dataAdditional: {
        decimalPlaces: 2,
      } as DataAdditionalNumbers,
    },
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },
  {
    field: 'sellPriExcl1',
    headerName: 'Price (excl)',
    hide: true,
    type: ['alignRight'],
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellEditor: false,
    cellRendererParams: {
      dataAdditional: {
        decimalPlaces: 2,
      } as DataAdditionalNumbers,
    },
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  // VAT --------------------------------
  {
    field: 'vatR',
    headerName: 'VAT Rate',
    hide: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  // GP ---------------------------------
  {
    field: 'gp1',
    headerName: 'GP (Avg. Cost)',
    hide: true,
    type: ['alignRight'],
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellEditor: false,
    cellRendererParams: {
      dataAdditional: {
        decimalPlaces: 2,
      } as DataAdditionalNumbers,
    },
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'prevGP',
    headerName: 'Previous GP',
    hide: true,
    type: ['alignRight'],
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellEditor: false,
    cellRendererParams: {
      dataAdditional: {
        decimalPlaces: 2,
      } as DataAdditionalNumbers,
    },
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },
  // UI ---------------------------------
  {
    field: 'lineColourCode',
    headerName: 'Line Colour',
    hide: true,
    width: 300,
    resizable: true,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_LINE_COLOUR,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },
  // Package ----------------------------
  {
    field: 'packSize',
    headerName: 'Pack Size',
    hide: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },

  // Days -------------------------------
  {
    field: 'daysDiff',
    headerName: 'Days Diff',
    hide: true,
    cellEditor: false,
    type: ['alignRight'],
  },
  // Statistics -------------------------
  {
    field: 'unitsYear',
    headerName: 'Units 12 Months',
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellEditor: false,
    hide: true,
    resizable: true,
  },
  // Created ----------------------------
  {
    field: 'isSelected',
    headerName: '',
    hide: false,
    pinned: 'right',
    lockPinned: true,
    lockPosition: 'right',
    maxWidth: 50,
    minWidth: 50,
    cellRenderer: CellRenderers.CELL_RENDERER_SELECTED,
    resizable: false,
  },
];

export const techoAdminNgpReportGridColDefs: ColDef[] = [];
//   [
//   {
//     field: 'newlyAdded',
//     headerName: 'Newly Added',
//     hide: true,
//     cellEditor: false,
//     cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
//     cellClassRules: {
//       'cell-changed': (params: CellClassParams<any, any>): boolean => {
//         const pos = params.column.getColDef().field;
//         return params.data.originalValue?.[pos]?.value !== params.data[pos];
//       }
//     },
//   },
//   {
//     field: 'manuallyAdded',
//     headerName: 'Manually Added',
//     hide: true,
//   },
//   // Store ------------------------------
//   {
//     field: 'storeId',
//     headerName: 'Store Number',
//     hide: true,
//     resizable: true,
//     cellEditor: false,
//     cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
//     cellClassRules: {
//       'cell-changed': (params: CellClassParams<any, any>): boolean => {
//         const pos = params.column.getColDef().field;
//         return params.data.originalValue?.[pos]?.value !== params.data[pos];
//       }
//     },
//   },
//   {
//     field: 'store',
//     headerName: 'Store',
//     hide: false,
//     resizable: true,
//     cellEditor: false,
//     cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
//     cellClassRules: {
//       'cell-changed': (params: CellClassParams<any, any>): boolean => {
//         const pos = params.column.getColDef().field;
//         return params.data.originalValue?.[pos]?.value !== params.data[pos];
//       },
//       'cell-disabled': (params: CellClassParams<any, any>): boolean => {
//         return params.data.disabled as boolean;
//       }
//     },
//   },
//   {
//     field: 'costMiss',
//     headerName: 'Cost Miss',
//     hide: true,
//     cellRenderer: CellRenderers.CELL_RENDERER_ICONS,
//     cellRendererParams: {
//       dataAdditional: {
//         isCostMissIconVisible: true,
//         costMissIconTooltip: costMissTooltip,
//       } as DataAdditionalIcons
//     },
//     cellClassRules: {
//       'cell-changed': (params: CellClassParams<any, any>): boolean => {
//         const pos = params.column.getColDef().field;
//         return params.data.originalValue?.[pos]?.value !== params.data[pos];
//       },
//       'cell-disabled': (params: CellClassParams<any, any>): boolean => {
//         return params.data.disabled as boolean;
//       }
//     },
//   },
//   {
//     field: 'createdDate',
//     headerName: 'Created Date',
//     hide: false,
//     valueFormatter: (params: ValueFormatterParams): string => DateUtils.dateToString(params.value),
//   },
//   {
//     field: 'created',
//     headerName: 'Created',
//     hide: true,
//     cellRenderer: CellRenderers.CELL_RENDERER_ICONS,
//     cellRendererParams: {
//       dataAdditional: {
//         isItemRecentlyCreatedIconVisible: true,
//         itemRecentlyCreatedIconTooltip: itemRecentlyCreatedTooltip,
//       } as DataAdditionalIcons
//     },
//   },
//   {
//     field: 'suppName',
//     headerName: 'Supplier Name',
//     hide: false,
//     resizable: true,
//     width: 400,
//     cellEditor: false,
//     suppressAutoSize: true,
//     cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
//     cellClassRules: {
//       'cell-changed': (params: CellClassParams<any, any>): boolean => {
//         const pos = params.column.getColDef().field;
//         return params.data.originalValue?.[pos]?.value !== params.data[pos];
//       },
//       'cell-disabled': (params: CellClassParams<any, any>): boolean => {
//         return params.data.disabled as boolean;
//       }
//     },
//   },
//   {
//     field: 'cratedHasSupp1',
//     headerName: 'Created Has Supplier',
//     hide: true,
//     width: 300,
//     cellEditor: false,
//     cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
//     cellClassRules: {
//       'cell-changed': (params: CellClassParams<any, any>): boolean => {
//         const pos = params.column.getColDef().field;
//         return params.data.originalValue?.[pos]?.value !== params.data[pos];
//       },
//       'cell-disabled': (params: CellClassParams<any, any>): boolean => {
//         return params.data.disabled as boolean;
//       }
//     },
//   },
//   {
//     field: 'isEdited',
//     headerName: 'Edited',
//     hide: false,
//     cellRenderer: CellRenderers.CELL_RENDERER_ICONS,
//     cellRendererParams: {
//       dataAdditional: {
//         isItemEditedIconVisible: true,
//         itemEditedIconTooltip: itemEditedTooltip,
//       } as DataAdditionalIcons
//     },
//   },
// ];
