<div [style]="'width: ' + width">
  <input #input (focus)="selectText()" (keyup)="onKeyUp($event)" [(ngModel)]="value"
         [disabled]="this.params.data._tags.includes('disabled')"/>

  <app-display-icon
    (click)="onUndoChangesClick()"
    *ngIf="params?.data?.originalValue?.[params.colDef.field]?.value !== value"
    [icon]="icons.backSpaceOutline">
  </app-display-icon>
</div>
