import {CellClassParams, ColDef, ValueFormatterParams} from 'ag-grid-community';
import * as CellRenderers from '../../../shared-modules/shared-grid/components-cell-renderers';
import {DataAdditionalNumbers} from '../../../shared-utilities/models-old/ngp-report-grid/data-additional-numbers';
import {DateUtils} from '../../../shared-utilities/utils-old/shared-utils-old/date-utils';
import {DataAdditionalIcons} from '../../../shared-utilities/models-old/ngp-report-grid/data-additional-icons';
import * as CellEditors from '../../../shared-modules/shared-grid/components-cell-editors';

const costMissTooltip = 'Latest cost is not the same as last GRV price.';
const itemRecentlyCreatedTooltip = 'Item was recently created.';
const itemEditedTooltip = 'Item was marked as edited.';
const itemErrorTooltip = `Item has an error and can't be submitted`;
export const stockManagerGridColDefs: ColDef[] = [
  // Icons ------------------------------
  {
    field: 'icons',
    headerName: '',
    hide: false,
    pinned: 'left',
    resizable: true,
    lockPinned: true,
    lockPosition: true,
    cellRenderer: CellRenderers.CELL_RENDERER_ICONS,
    cellRendererParams: {
      dataAdditional: {
        isCostMissIconVisible: true,
        costMissIconTooltip: costMissTooltip,
        isItemRecentlyCreatedIconVisible: true,
        itemRecentlyCreatedIconTooltip: itemRecentlyCreatedTooltip,
        isItemEditedIconVisible: true,
        itemEditedIconTooltip: itemEditedTooltip,
        isErrorIconVisible: true,
        itemErrorIconTooltip: itemErrorTooltip,
      } as DataAdditionalIcons,
    },
    width: 100,
  },
  // General ----------------------------
  {
    field: 'code',
    headerName: 'Code',
    hide: false,
    pinned: 'left',
    resizable: true,
    lockPinned: true,
    lockPosition: true,
    type: ['alignRight'],
    editable: false,
    width: 149,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_DISABLE_ITEM,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
  },

  {
    field: 'desc',
    headerName: 'Description',
    hide: false,
    lockPinned: true,
    width: 400,
    resizable: true,
    editable: false,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    hide: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'binL',
    headerName: 'Bin Location',
    hide: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'lastPurchase',
    headerName: 'Last Purchase',
    hide: true,
    cellEditor: false,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
  },
  {
    field: 'lastSold',
    headerName: 'Last Sold',
    hide: true,
    cellEditor: false,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
  },
  {
    field: 'dlvColl',
    headerName: 'Delivery Collection',
    hide: true,
    resizable: true,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
  },
  {
    field: 'dep',
    headerName: 'Department',
    hide: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_SELECT_DROPDOWN,
    cellRenderer: CellRenderers.CELL_RENDERER_DEPARTMENTS,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'subDep',
    headerName: 'Sub Department',
    hide: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_SELECT_DROPDOWN,
    cellRenderer: CellRenderers.CELL_RENDERER_DEPARTMENTS,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'excludeSellingValue',
    headerName: 'Exclude Selling Value',
    hide: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
  },
  {
    field: 'gp1',
    headerName: 'GP (Average Cost)',
    hide: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'suppUsedLastDate1',
    headerName: 'Supplier Used Last Date 1',
    hide: true,
    cellEditor: false,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
  },
  {
    field: 'genCode',
    headerName: 'General Code',
    hide: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'latestCost',
    headerName: 'Latest Cost',
    hide: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
  },
  {
    field: 'onHand',
    headerName: 'On Hand',
    hide: false,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
  },
  {
    field: 'nominalGP',
    headerName: 'Nominal GP',
    hide: false,
    type: ['alignRight'],
    cellEditor: CellEditors.CELL_EDITOR_PRICE_NGP,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'onHoldCode',
    headerName: 'On Hold Code',
    hide: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_MULTI_SELECT,
    cellRenderer: CellRenderers.CELL_RENDERER_MULTI_SELECT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'ordLvl',
    headerName: 'Order Level',
    hide: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'packSize',
    headerName: 'Pack Size',
    hide: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'suppUsedLastDate2',
    headerName: 'Supplier Used Last Date 2',
    hide: true,
    cellEditor: false,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
  },
  {
    field: 'prvSellingPri',
    headerName: 'Previous Selling Price',
    hide: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
  },
  {
    field: 'prvSellingPriDate',
    headerName: 'Previous Selling Price Date',
    hide: true,
    cellEditor: false,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
  },
  {
    field: 'sellPriExcl1',
    headerName: 'Selling Price (Excl)',
    hide: true,
    type: ['alignRight'],
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
  },
  {
    field: 'sellPriIncl1',
    headerName: 'Selling Price (Incl)',
    hide: false,
    type: ['alignRight'],
    cellEditor: CellEditors.CELL_EDITOR_PRICE_NGP,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return +params.data.originalValue?.[pos]?.value !== +params.data[pos];
      },
    },
  },
  {
    field: 'purchaseOrder',
    headerName: 'Purchase Order',
    hide: true,
    resizable: true,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
  },
  {
    field: 'recommendedGP',
    headerName: 'Recommended GP',
    hide: true,
    type: ['alignRight'],
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'regularSuppCode',
    headerName: 'Regular Supplier Code',
    hide: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_SUPPLIER,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'lineColourCode',
    headerName: 'Line Colour',
    hide: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_SELECT_DROPDOWN,
    cellRenderer: CellRenderers.CELL_RENDERER_LINE_COLOUR,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'suppCode',
    headerName: 'Supplier Code',
    hide: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      'cell-changed': (params: CellClassParams): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      'cell-disabled': (params: CellClassParams): boolean => {
        return params.data.disabled as boolean;
      },
    },
  },
  {
    field: 'unitsYear',
    headerName: 'Units Sold (Year)',
    hide: false,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    resizable: true,
  },
];
