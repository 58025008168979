import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreRoutingModule} from './core-routing.module';
import {Action, ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {sharedFilterReducer} from './store-shared-filter/shared-filter.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SharedFilterEffects} from './store-shared-filter/shared-filter.effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../../environments/environment';
import {ngpReportReducer} from '../../features-as-modules/feature-ngp-report/store/ngp-report.reducer';
import {NgpReportEffects} from '../../features-as-modules/feature-ngp-report/store/ngp-report.effects';
import {sharedReducer} from './store-shared/shared.reducer';
import {SharedEffects} from './store-shared/shared.effects';
import {stockManagerReducer} from '../stock-manager/store/stock-manager.reducer';
import {StockManagerEffects} from '../stock-manager/store/stock-manager.effects';

export const debug = (reducer: ActionReducer<never>): ActionReducer<never> =>
  (state, action: Action) => {
    if (!environment.production) {
      // console.warn('[State] ---> ', state);
      // console.warn(`[Action] --> ${action.type}`, action);
      // console.warn('\n');
    }
    return reducer(state, action);
  };

export const metaReducers: MetaReducer[] = !environment.production ? [debug] : [];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreRoutingModule,
    StoreModule.forRoot({
      ['core-shared']: sharedReducer,
      ['ngp-report']: ngpReportReducer,
      ['shared-filter']: sharedFilterReducer,
      ['stock-manager']: stockManagerReducer,
    }, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictStateSerializability: false,
      },
    }),
    EffectsModule.forRoot([
      NgpReportEffects,
      SharedEffects,
      SharedFilterEffects,
      StockManagerEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
    }),
  ],
})
export class CoreModule {
}
