<div [ngStyle]="{'width': width + 'px'}" class="line-colour-selector-div">

  <input #input (focus)="selectText()" [(ngModel)]="value" [disabled]="this.params.data._tags.includes('disabled')"
         class="input">
  <select
    (change)="onSelectionChange($event)"
    [(ngModel)]="value"
    [disabled]="this.params.data._tags.includes('disabled')"
    [ngStyle]="{'width': selectorWidth + 'px'}"
    class="select-line-colour-list">
    <option *ngIf="colDefField === 'lineColourCode'" value="0">No Line Colour</option>
    <option *ngFor="let option of options" [style.background]="option.value? option.value : 'var(--ion-color-light-v1)'"
            [value]="option.key">
      {{ option.description }}
    </option>
  </select>

  <app-display-icon
    (click)="onUndoChangesClick()"
    *ngIf="params.data.originalValue[params.colDef.field].value !== value"
    [icon]="icons.backSpaceOutline"
    class="backspace">
  </app-display-icon>

</div>
