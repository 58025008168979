import {IPageObj, ISubPageObj} from './page';
import {Icons} from '../../../shared-modules/shared-icons/icons';

export const PAGES: IPageObj[] = [
  {page: 'home', text: 'Home', icon: Icons.home},
  {page: 'dashboard', text: 'Dashboard (Temporary)', icon: Icons.pieChart},
  {page: 'login', text: 'Sign In', icon: Icons.logIn},
  {page: 'stock-manager', text: 'Stock Manager', icon: Icons.create},
  {page: 'ngp-report', text: 'NGP Report', icon: Icons.clipboard},
  {page: 'auto-ordering', text: 'Auto Ordering', icon: Icons.cart, multiple: true, tab: 'orders'},
  {page: 'shelf-talkers', text: 'Shelf Talkers', icon: Icons.priceTags},
  {page: 'barcode-scanner', text: 'Barcode Scanner', icon: Icons.barcode},
  {page: 'user-access', text: 'Staff Access', icon: Icons.key},
  {page: 'admin-info', text: 'Admin Page', icon: Icons.fileTrayStacked},
  // Admin's Pages
  {page: 'admin-internal', text: 'Admin Internal', icon: Icons.planet},
  {page: 'testing', text: 'Testing', icon: Icons.terminal},
  {page: 'stock-match', text: 'Stock Match Idea', icon: Icons.pint},
];

export const PAGES_SUB: ISubPageObj = {
  ['auto-ordering']: [
    {page: 'auto-ordering', text: 'Orders ', icon: Icons.today, tab: 'orders'},
    {page: 'auto-ordering', text: 'Edit Schedule', icon: Icons.calendar, tab: 'schedule'},
    {page: 'auto-ordering', text: 'Unscheduled', icon: Icons.calendarClear, tab: 'unscheduled'},
    {page: 'auto-ordering', text: 'Automation Settings', icon: Icons.settings, tab: 'settings'},
  ],
};
