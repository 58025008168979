import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SettingsState} from './settings.reducer';
import {selectSelectedUserStore} from '../../../features-as-modules/feature-core/store/core.selectors';
import {IStore} from '../../../shared/shared-models/store/store';

const selectSettingsState = createFeatureSelector<SettingsState>('settings');

// Select NGP Report User Settings
export const selectNGPReportColPreviewSettings = createSelector(
  selectSettingsState,
  (state: SettingsState) =>
    state.userSettings?.ngpReportColPreviewSettings,
);

// Select Stock Manager Column Preview Settings
export const selectStockManagerColPreviewSettings = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, store: IStore): { [key: string]: string } | undefined => {
    const settings = state?.userSettings?.stockManagerColPreviewSettings;
    return settings?.[store.storeId] as { [key: number]: string } ?? settings;
  },
);


// Select Disabled Rules
export const selectRulesDoc = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, selectedStore: IStore) =>
    state.storeSettings[selectedStore.storeId]?.disabledRules,
);

// Select Line Colour
export const selectLineColour = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, selectedStore: IStore) =>
    state.storeSettings[selectedStore.storeId]?.lineColour,
);

// Select Table Navigation Settings
export const selectNavSettings = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, selectedStore: IStore) => state.userSettings.tableNavigationSettings,
);

export const selectFitToGrid = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.userSettings.fitToGrid,
);

export const selectAllUsersEmailSettingForCurrentSelectedStore = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, store: IStore) => state.usersEmailSettings[store.storeId],
);

export const selectIsUserEmailsLoading = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isUserEmailSettingsLoading,
);
