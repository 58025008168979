import {GridOptions, RowClassParams} from 'ag-grid-community';

/**
 * The Grid Options for the Stock Manger
 */
export const stockManagerGridOptions: GridOptions = {
  colResizeDefault: 'shift',
  debug: false,
  editType: null,
  enterNavigatesVertically: true,
  enterNavigatesVerticallyAfterEdit: true,
  suppressAnimationFrame: true,
  enableCellTextSelection: false,
  multiSortKey: 'ctrl',
  rowBuffer: 100,
  pagination: true,
  rowHeight: 45,
  suppressScrollOnNewData: true,
  paginationAutoPageSize: false,
  paginationPageSize: 20,
  paginationPageSizeSelector: [10, 15, 20, 50, 100],
  suppressPaginationPanel: true,
  rowDragManaged: true,
  rowDragEntireRow: true,
  rowDragMultiRow: true,
  rowSelection: 'multiple',
  singleClickEdit: false,
  stopEditingWhenCellsLoseFocus: true,
  suppressRowClickSelection: true,
  undoRedoCellEditing: false,
  undoRedoCellEditingLimit: 25,
  getRowStyle: (params: RowClassParams): { [key: string]: string } => ({
    backgroundColor: `${params.data.lineColourValue}`,
    color: `${params.data.lineColourValue ? 'black' : 'initial'}`,
    fill: `${params.data.lineColourValue ? 'black' : 'var(--ion-color-white-contrast)'}`,
  }),
};

export const stockMangerVisibleFieldsDefault: string[] = [
  "icons",
  "code",
  "desc",
  "nominalGP",
  "onHand",
  "sellPriIncl1",
  "unitsYear"];
