<div>

  <ng-container *ngIf="additionalData.isErrorIconVisible" class="icon-container">
    <ng-container *ngIf="additionalData.error || params.data['isError'] === true">
      <app-display-icon
        [border]="false"
        [colour]="'red'"
        [icon]="icons.bugOutline"
        [tooltip]="additionalData?.itemErrorIconTooltip">
      </app-display-icon>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="additionalData.isCostMissIconVisible" class="icon-container">
    <ng-container *ngIf="additionalData.costMiss">
      <app-display-icon
        [border]="false"
        [colour]="'yellow'"
        [icon]="icons.notEqualVariant"
        [tooltip]="additionalData?.costMissIconTooltip">
      </app-display-icon>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="additionalData.isItemRecentlyCreatedIconVisible" class="icon-container">
    <ng-container *ngIf="params.data?.['created'] >= params.data?.['suppUsedLastDate1'] && !isStockManager">
      <app-display-icon
        [border]="false"
        [colour]="'green'"
        [icon]="icons.newBox"
        [tooltip]="additionalData?.itemRecentlyCreatedIconTooltip">
      </app-display-icon>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="additionalData.isItemEditedIconVisible" class="icon-container">
    <ng-container *ngIf="additionalData.isEdited || params.data['isEdited'] === true">
      <app-display-icon
        [border]="false"
        [colour]="'yellow'"
        [icon]="icons.pencil"
        [tooltip]="additionalData?.itemEditedIconTooltip">
      </app-display-icon>
    </ng-container>
  </ng-container>

  <ng-container *ngIf=" params.data._tags.includes('disabled')">
    <app-display-icon
      [border]="false"
      [colour]="'red'"
      [icon]="icons.lockClosedOutline"
      [tooltip]="'This Item Is Disabled'">
    </app-display-icon>
  </ng-container>

</div>
